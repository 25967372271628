import PersonalProjects from "../personalProjects/PersonalProjects";
import ProfessionalProjects from "../professionalProjects/ProfessionalProjects";

function Projects() {
    return (
        <>
        <PersonalProjects />
        <ProfessionalProjects />
        </>
    );
}

export default Projects;